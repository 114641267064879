<template>
    <div>
        <el-card class="mt-2 ml-4 mr-4 bg-white border-white">

            <div style="width: 100%; margin: auto; " class="font-semibold text-lg">
                <el-divider direction="vertical" style="color: #7d89c3;width: 5px;"></el-divider>{{kindergartenName}}
            </div>
            <!-- 查询框 -->
            <el-form :model="searchData" class="demo-form-inline mt-4 pl-6 pr-6" :label-width="labelWidth">
                <el-row class="flex">
                    <el-input class="mr-2" v-model="searchData.cName" size="small" style="width: 8rem;"
                        placeholder="班级名称">
                    </el-input>
                    <el-input class="mr-2" v-model="searchData.pName" size="small" style="width: 8rem;"
                        placeholder="家长姓名">
                    </el-input>
                    <el-input class="mr-2" v-model="searchData.sName" size="small" style="width: 8rem;"
                        placeholder="学生姓名">
                    </el-input>
					<el-input class="mr-2" v-model="searchData.pPhone" size="small" style="width: 8rem;"
					    placeholder="家长联系电话">
					</el-input>
                    <el-button type="primary" plain size="small" style="background-color: #706fa8c3;color: white;"
                        icon="el-icon-search" @click="search()">搜索</el-button>
                    <el-button type="primary" size="small" style="background-color: white;color: black;" @click="reset()"
                        icon="el-icon-refresh" plain>重置</el-button>
                </el-row>
            </el-form>
            <!-- <div class="flex justify-end text-xs mt-4">*该表格的已到状态会在当日的24:00后自动更新</div> -->
            <!-- 表格 -->
            <el-tabs v-model="activeName" @tab-click="handleClick" class="mt-4 ml-4">
                <el-tab-pane :label="'全部 '+total" name="first">
                   <el-table :data="tableData" border style="width: 100%;margin-top: -1rem;">
                       <el-table-column prop="children.id" label="" min-width="10%">
                       </el-table-column>
                       <el-table-column prop="children.name" label="学生姓名" min-width="10%">
                       </el-table-column>
                       <el-table-column prop="class.year" label="入学年份" min-width="10%">
                       </el-table-column>
                       <el-table-column prop="class.name" label="班级名称" min-width="10%">
                       </el-table-column>
                   	<el-table-column prop="parent[0].name" label="家长姓名" min-width="10%">
                   	</el-table-column>
                   	<el-table-column prop="parent[0].phone" label="家长联系电话" min-width="20%">
                   	</el-table-column>
                       <el-table-column label="家长详情" min-width="10%">
                           <template slot-scope="scope">
                               <el-button size="small" type="text" @click="detail(scope.row),(dialog=true)">详情</el-button>
                           </template>
                       </el-table-column>
                   </el-table>
                </el-tab-pane>
            </el-tabs>
            <!-- <div class="flex justify-end text-xs mt-4">*该表格的已到状态会在当日的24:00后自动更新</div> -->
            <!-- 分页 -->
            <div class="flex  bg-white ml-4 mt-6 pt-4 pb-4">
                <div></div>
                <Pagination :currentPage="currentPage" :total="total" :pageSize="pageSize" @pageChange="pageChange" />
            </div>
        </el-card>

        <!-- 对话框 -->
    <el-dialog :visible.sync="dialog" width="800px"  title="家长详情" :close-on-click-modal="false">
        <!-- 分割线 -->
        <el-divider></el-divider>
        <div class=" ml-6 mr-6 mt-6 mb-4">
          <div style="width: 100%; margin: auto; " class="font-semibold text-sm">
            <el-divider direction="vertical" style="color: #7d89c3;width: 8px;"></el-divider>孩子信息
          </div>
          <div class="ml-6 mr-6 mt-2 mb-8 flex">
            <div class="mr-10"><span>孩子名称：{{childrenData.name}}</span></div>
            <div class="mr-10"><span>学校名称：{{kindergartenName}}</span></div>
            <div class="mr-10"><span>班级名称：{{childrenData.class}}</span></div>
          </div>
          <div style="width: 100%; margin: auto; " class="font-semibold text-sm mt-8">
            <el-divider direction="vertical" style="color: #7d89c3;width: 8px;"></el-divider>家长信息
          </div>
          <div class="ml-6 mr-6 mt-2 ">
            <div v-for="(item,index) in parentData" class="mt-2"><span class="mr-10">家长名称：{{item.name}}</span><span>联系电话：{{item.phone}}</span></div>
          </div>
          <div class="mt-6"></div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import Pagination from "../../components/Pagination/pagination";
    export default {

        data() {
            return {
                form: {
                    imgUrl: "", //图片字段
                    platName: "", //自定义字段
                },
                activeName: 'first',
                labelWidth: "100px",
                searchData: {
                    sName: '',
                    pName:'',
                    pPhone:'',
                    cNmae:'',
                    createTime:''
                },
                tableData: [],
                // 对话框
                isAdd: false, //区分添加和编辑
                dialog: false,
                // 分页
                total: 0,
                currentPage: 1,
                pageSize:10,
                school_id:'',//集团id
                nursery_id:'',//幼儿园id
                kindergartenName:'',
                childrenData:{
                    name:'',
                    class:''
                },
                parentData:[]
            };
        },

        computed: {},
        components:{
            Pagination 
        },
        watch: {},
        mounted() {
            this.school_id=window.sessionStorage.getItem("school_id")
            this.kindergartenName=window.sessionStorage.getItem("kindergarten")
            this.nursery_id=window.sessionStorage.getItem("nursery_id")
            this.getList()
        },
        methods: {
            handleClick(tab, event) {
                console.log(tab, event);
            },
            //重置
            reset(){
                this.pageSize=10
                this.searchData={}
                this.currentPage=1
                this.getList()
            },
            //学生详情
            detail(row){
                this.childrenData.name=row.children.name
                this.childrenData.class=row.class.name
                this.parentData=row.parent
            },
            // 获取基本数据
            async getList() {
                const RES = await this.http.post("admin/student/all", {
                    limit: 10,
                    page: this.currentPage,
                    nursery_id:this.nursery_id
                });
				console.log(RES);
                if (RES.code == 200) {
                     this.tableData = RES.data;
                     this.total = RES.total;
                }
            },
            //分页
            pageChange(index) {
                this.currentPage = index._currentPage
                this.pageSize = index._pageSize
                if (this.check.checkEmpty(this.searchData)) {
                    //检查查询字段是否都为空，都为空则表示普通分页，不都为空则表示分页查询
                    this.getList();
                } else {
                    this.commonData();
                }
            },
              //查询
              search() {
                this.currentPage = 1;
                this.commonData();
            },
            // 分页&&查询通用数据 分页查询的情况比较多 ，坑比较多 ，建议和我写成一样或者自我优化
            async commonData() {
                if (this.searchData.createTime == null) {
                    this.searchData.createTime = [];
                }
                const RES = await this.http.post("admin/student/search", {
                    limit: this.pageSize,
                    page: this.currentPage,
                    childName: this.searchData.sName,
                    className: this.searchData.cName,
                    parentName: this.searchData.pName,
                    phone:this.searchData.pPhone,
                    nursery_id:this.nursery_id
                });
                if (RES.code == 200 || RES.code == 204) {
                    this.tableData = RES.data;
                    this.total = RES.total;
                }
                if (RES.code == 400) {
                    this.$message({
                        message: '暂无数据，请重新选择查询条件',
                        type: 'warning'
                    });
                }
            },
        },
    };
</script>
<style lang="scss" scoped>
     /deep/.el-dialog__headerbtn .el-dialog__close {
        color: black;
    }

    /deep/.el-dialog {
        height: 400px;
    }

    /deep/.el-dialog__header {
        background-color: #9473ee;
        padding: 10px 10px 10px;
    }

    /deep/.el-tabs__header {
        background-color: #d9dceca3;
    }

    /deep/.el-dialog__headerbtn {
        top: 10px;
    }

    /deep/.el-dialog__title {
        color: white;
        font-size: 1rem;
    }

    /deep/.el-table th {
        background-color: white !important;
    }

    /deep/.el-tabs__nav {}

    /deep/.el-tabs__item.is-active {
        background-color: #d4caf1;
        color: #535c8d;
    }

    /deep/.el-tabs__active-bar {
        background-color: #535c8d;
		margin-left: -1.8rem;
    }

    /deep/.el-table td,
    .el-table th {
        text-align: center;
    }

    /deep/.el-tabs--top .el-tabs__item.is-top:nth-child(2){
        padding: 0 30px;
    }

    /deep/.el-table th {
        text-align: center;
        background: rgb(236, 235, 235);
    }

    /deep/ .avatar-uploader .el-upload {
        border: 1px dashed hsl(0, 0%, 85%);
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background: rgb(204, 204, 204);
    }

    /deep/.avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    /deep/ .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 200px;
        height: 150px;
        line-height: 150px;
        text-align: center;
    }

    /deep/ .avatar {
        width: 200px;
        height: 150px;
        display: block;
    }

    /deep/.el-form-item {
        margin-bottom: 5px;
    }

    /deep/.el-dialog__body {
        padding: 0px;
    }

    /deep/.el-divider--horizontal {
        margin: 0px;
    }

    /deep/.el-tabs__header {
        background-color: #f8f8f8;
    }

      /deep/.el-tabs__item.is-active {
        background-color: #ecedf3;
        color: #535c8d;
    }
    /deep/.el-dialog__header {
        background-color: #9473ee;
        padding: 10px 10px 10px;
    }
    /deep/.el-tabs__active-bar {
        background-color: #7d89c3;
        margin-left: -1.8rem;
    }
    /deep/.el-button--primary.is-plain{
            border-color: inherit;
    }
</style>